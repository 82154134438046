.btn_uni {
    position: relative;
}

.btn_uni>div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    color: white;
    font-size: 11px;
}

p {
    font-size: 14px;
    font-weight: 300;
}