@font-face {
    font-family: 'AppleStorm';
    font-style: normal;
    font-weight: normal;
    src: url(../src/style/font/applestorm/AppleStormCBo.otf);
}

@font-face {
    font-family: 'Clash Display Variable';
    font-style: normal;
    font-weight: normal;
    src: url(../src/style/font/ClashDisplay_Complete/Fonts/OTF/ClashDisplay-Medium.otf);
}

@font-face {
    font-family: 'PP Mori';
    font-style: normal;
    font-weight: normal;
    src: url(../src/style/font/pp-mori/PPMori/PPMori-Regular.otf);
}

.accordion-button {
    font-family: 'PP Mori';
    font-weight: 300 !important;
    font-size: 12px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Clash Display Variable';
}

.btn_uni>div {
    font-family: 'PP Mori';
}

body {
    --bs-body-bg: #030811;
    --bs-body-color: #f4f4f4;
}

.nav-link {
    --bs-nav-link-color: white;
    --bs-nav-link-font-weight: 400;
    margin-right: 15px;
}

.navbar-nav {
    width: 100%;
    justify-content: center;
    margin-left: 100px;
}