.banner {
    background-image: url(../../../public/images/banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2% 0px;
}

.banner h1 {
    font-size: 55px;
    color: rgba(226, 207, 81, 1);
    font-family: Clash Display Variable;
    font-weight: 700;
}

.bnner_tx .head {
    color: white;
    font-size: 22px;
    font-family: Clash Display Variable;
    padding-bottom: 8px;
}

.bnner_tx p {
    color: white;
    font-weight: 300;
}

.banner_logos {
    border-radius: 12px;
    border: 1px solid #2F323C;
    background: var(--Secondery-Button, #181D27);
    background: rgba(24, 29, 39, 1);
    border-radius: 8px;
    text-align: center;
    padding: 6px 0px;
}

.banner_logos img {
    height: 50px;
    width: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}

.bnner_img .ms-0 {
    width: 300px;
}

.bnner_tx {
    width: 75%;
}

.bnner_tx .btn_uni img {
    width: 150px;
}

/* ========================== aboute banner =========================== */

.about_banner {
    background: #030811;
}

.about_banner p {
    font-size: 14px;
    font-weight: 300;
}

.about_banner .a_b {
    border-radius: 32px;
    border: 1px solid var(--Stoke, #2F323C);
    background: rgba(24, 29, 39, 0.10);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 3% 1%;
}

.roket {
    position: absolute;
    bottom: -190px;
    right: -18px;
}

/* ========================================= our product ================================= */
.our_product .coin {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    background: #113230;
}

.our_product .coin_txt {
    display: flex;
    align-items: center;
    background: #10131c;
    padding: 9px;
}

.our_product .coin_txt>div>div {
    font-family: 'Clash Display Variable';
    color: rgba(21, 181, 132, 1);
}

.our_product .coin_txt p {
    font-size: 12px;
    font-weight: 300;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 21px solid transparent;
    border-top: 24px solid #030811;
    position: absolute;
    left: -1px;
    top: -1px;
    transform: rotate(1deg);
}

.triangle-down2 {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 21px solid transparent;
    border-top: 24px solid #030811;
    position: absolute;
    right: -1px;
    bottom: -1px;
    transform: rotate(183deg);
}

/* =================================================== our_features =================================================== */

/* unlock power */
.up_box {
    padding: 25px;
    border-radius: 20.507px;
    border: 1.282px solid var(--Stoke, #2F323C);
    background: var(--colors-alias-black-white-black-800, #0D0E14);
}

/* unlock power */

.our_features .box {
    border-radius: 24px;
    background: var(--Light-Dark-BG, #0A0E19);
    padding: 25px;
    height: 100%;
}


/* ================== your_business ================= */

.form-control {
    border-radius: 9.038px;
    border: 1.13px solid rgba(159, 159, 159, 0.40);
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.4);

}

.yb_box {
    padding: 25px;
    border-radius: 20.507px;
    border: 1.282px solid var(--Stoke, #2F323C);
    background: var(--colors-alias-black-white-black-800, #0D0E14);
}

/* ================== your_business ================= */


/* ================= Faq ================= */
.accordion-item {
    color: var(--bs-accordion-color);
    background-color: #0a0e19;
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    border-radius: 10px !important;
}

.accordion-button:not(.collapsed) {
    --bs-accordion-active-color: rgba(211, 213, 217, 1);
    --bs-accordion-active-bg: #0a0e19;
}

.accordion-button {
    --bs-accordion-btn-bg: #0a0e19;
    --bs-accordion-btn-color: rgba(211, 213, 217, 1);
    border-radius: 10px !important;
}

.accordion {
    --bs-accordion-border-color: rgba(47, 50, 60, 1);
}

.accordion-item {
    margin-bottom: 20px;
}

.accordion-button:focus {
    --bs-accordion-btn-focus-box-shadow: 0px 0px 0px 0px gray;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
    --bs-accordion-btn-icon: url(../../../public/images/logos/download.png);
    --bs-accordion-btn-active-icon: url(../../../public/images/logos/download.png);
    opacity: .5;
}

/* ================= Faq ================= */

/* ================== contact us =================== */
.cu_box {
    padding: 30px;
    border-radius: 16px;
    background: var(--Light-Dark-BG, #0A0E19);
}

.contact_us .get_touch {
    display: flex;
    align-items: center;
}

.contact_us .get_touch span:nth-child(2) {
    font-size: 13px;
    display: flex;
    align-items: center;
}

.contact_us input,
.contact_us textarea {
    border-radius: 8px;
    border: 1px solid var(--Stoke, #2F323C);
    background: rgba(24, 29, 39, 0.30);
}

/* ============= footer ====================== */

.footer{
    background: #0b151e;
}

.footer_links div a {
    text-decoration: none;
    font-size: 14px;
    color: white;
    font-weight: 300;
}

.ftr_icn{
    display: flex;
    margin: 0px 5px;
    font-size: 22px;
    color: rgba(21, 181, 132, 1);
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .bnner_tx {
        width: 100%;
    }

    .bnner_img .ms-0 {
        width: 185px;
    }

    .banner_logos img {
        height: 18px;
    }
}