/* .header {
    background: #0a020261;
    position: fixed;
    width: 100%;
    backdrop-filter: blur(10px);
} */

.header {
    background: #0b0d13;
}

.bg-body-tertiary {
    --bs-tertiary-bg-rgb: transparent;
}

.logo {
    display: inline-flex;
    align-items: center;
    color: white;
}

.logo_text {
    font-family: AppleStorm;
}

.logo_text span {
    color: #15b584;
}

button.navbar-toggler, button.navbar-toggler.collapsed {
    vertical-align: super;
    background: #e2cf51;
}

.navbar-toggler-icon {
    width: 18px;
    height: 18px;
    background-size: 130%;
    color: white;
}

.navbar-toggler:focus {
    --bs-navbar-toggler-focus-width: 0px;
}

.nav-link:focus, .nav-link:hover, .navbar-nav .nav-link.active {
    --bs-nav-link-hover-color: rgb(21 181 132);
    --bs-navbar-active-color: rgb(21 181 132);
    font-weight: 500;
}