@import url("https://use.typekit.net/dyx2jtv.css");

.coming_soon {
    height: 100vh;
    position: relative;
}

.bg_img {
    height: 100vh;
    width: 100%;
    filter: brightness(0.5);
    object-fit: cover;
}

.mask {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 64%);
}

.content_box {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden;
}

.heading {
    font-size: 72px;
    font-weight: 600;
    background: -webkit-linear-gradient(#ffc21ae8, #f0ff1ae8, #12746b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-family: "Reddit Mono", monospace; */
    font-family: "bebas-neue-pro", sans-serif;
}

.coming_soon p {
    font-size: 25px;
    color: rgb(194, 194, 194);
    /* font-family: "Open Sans", sans-serif; */
    font-family: "Montserrat", sans-serif;
}

.logo {
    height: auto;
    width: 20%;
}

.perent_p{
    padding: 0px 15%;
}

/* ==============================type writing animation================================== */
.typewriter,
.typewriter1 {
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    animation: typing;
    animation-delay: 1s;
    animation-duration: 1.5s;
    animation-timing-function: steps(30, end);
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

.typewriter1 {
    animation-delay: 2.5s;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* =================================animation=================================== */

.area {
    background: #4e54c8;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    /* height:100vh; */


}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    /* background: rgba(255, 255, 255, 0.2); */
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .heading {
        font-size: 30px;
    }

    p {
        font-size: 15px !important;
    }

    .perent_p{
        padding: 0px 0%;
    }
}